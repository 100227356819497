/* RetroSearchPage.css */
  
  .search-bar {
    display: flex;
    margin-top: 20px;
  }
  
  input {
    flex: 1;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #d0d0d0; /* Light gray border */
    border-radius: 2px;
    background-color: #fff; /* White background resembling paper */
    color: #333; /* Dark text color for readability */
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  input::placeholder {
    color: #999; /* Light gray placeholder text */
  }
  
  button:hover {
    background-color: #e2b874; /* Lighter gold on hover */
    color: #fff; /* White text on hover */
  }
  /* Add this to your SearchBar.css or a separate CSS file */

.button-row {
    display: flex;
    justify-content: space-between;
    /* ... other styles for the button row */
  }
  
  .button-row button {
    /* Add styling for the buttons */
    border: 1px solid #ccc;
    background-color: #f0f0f0;
    padding: 8px 12px;
  }
  