/* RetroYouTubePlayer.css */

.retro-youtube-player {
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #d0d0d0; /* Light gray border resembling paper */
    border-radius: 10px;
    background-color: #f8f8f8; /* Light background color resembling paper */
    overflow: hidden;
  }
  
  iframe {
    width: 100%;
    height: 400px;
    border: none;
  }
  
  .controls {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  button {
    padding: 10px;
    background-color: #dbac5a; /* Vintage gold */
    color: #312c24; /* Dark retro text color */
    border: none;
    border-radius: 2px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  button:hover {
    background-color: #e2b874; /* Lighter gold on hover */
    color: #fff; /* White text on hover */
  }
  
  .speed-controls {
    display: flex;
    align-items: center;
  }
  
  span {
    margin-right: 10px;
    color: #fff;
  }
  
  /* Added margin between speed control buttons */
.speed-controls button {
    margin-right: 4px; /* Adjust the margin value as needed */
  }