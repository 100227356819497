/* RetroStyles.css */

body {
    background-color: #91B7C4;
    /* Light background color resembling paper */
    color: #333;
    /* Dark text color for readability */
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
}

.container {
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #d0d0d0;
    /* Light gray border resembling paper */
    border-radius: 1px;
    background-color: #f8f8f8;
    /* Light background color resembling paper */
}
.page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    /* Adjust the padding-top as needed */
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #333;
    /* Dark text color for headings */
}

p {
    line-height: 1.6;
}

a {
    color: #007bff;
    /* Link color */
}

a:hover {
    color: #0056b3;
    /* Link color on hover */
}
