.video-search-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    /* Adjust the padding-top as needed */
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

.logo {
    width: 150px;
    /* Adjust the width as needed */
    height: 150px;
    /* Adjust the height as needed */
}